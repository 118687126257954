/** @jsx jsx */


import OrtYaml from '../data/orte.yaml'
import AusstellungYaml from '../data/veranstaltung.yaml'
import KategorienYaml from '../data/veranstaltungkat.yaml'
import ZeitenYaml from '../data/zeiten.yaml'
import Layout from '../components/layout'
import MySEO from '../components/seo'
import Nichts from '../components/nichts'
import {Container,jsx} from 'theme-ui'
import SelectBox from '../components/SelectBox'
//import { graphql } from 'gatsby'


const Veranstaltung = () => {
   
    const orte = OrtYaml.content
    const kategorien = KategorienYaml.content
    const thema= AusstellungYaml.content
    const zeiten= ZeitenYaml.content
    
    
    if(orte&&kategorien&&thema)
    return(
        <Layout>
            <MySEO title="Veranstaltungen im Oberen Mittelrheintal" description="Veranstaltungen im Oberen Mittelrheintal" />
           <Container>
 
           <SelectBox kategorien={kategorien} orte={orte} thema={thema} zeiten={zeiten} titel="Veranstaltungen" />
         </Container> 
        </Layout>
    )
    return (<Layout><Container sx={{m:1}}><Nichts message="Im Moment haben wir keine Veranstaltungen auf unserer Webseite registriert..."/></Container></Layout>)

}
        

 export default Veranstaltung