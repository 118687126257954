/** @jsx jsx */

import {jsx} from 'theme-ui'
import {useSpring, animated} from 'react-spring'

const Nichts = ({message}) => {
    const props = useSpring({to:{opacity:1,left:0}, from: {opacity:0,left:-40},config:{duration:1000}})


    return (<animated.div style={props} sx={{position:`relative`,display:`flex`,zIndex:300,maxWidth:700,width:`97%`}}><p sx={{color:`text`,maxWidth:`90%`,fontSize:2}}>{message}</p></animated.div>)
}

export default Nichts